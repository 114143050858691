import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';

import Layout from '../components/Layout';
import Stage from '../components/Stage';
import Section from '../components/Section';
import Intro from '../components/Intro';
import { P } from '../components/Typography';
import Container from '../components/Container';
import List from '../components/List';
import ContactTeaser from '../components/ContactTeaser';

import { getImageFromNode } from '../helpers/utils';

/** Bild im Rechts-Links-Wechsel */
const Img = styled(GatsbyImage)`
    width: 100%;
`;

const ServicesPage = ({ data }) => {
    const { stageImage, contentImages } = data;
    return (
        <Layout title="Leistungen" description="Schütz Dienstleitungen - Vielfältige Leistungen">
            <Stage image={stageImage.childImageSharp} title="Leistungen" />
            <Section>
                <Container size="l" grid cols="2" alignItems="center" reverseOnMobile>
                    {/* eslint-disable react/jsx-wrap-multilines */}
                    <Intro
                        headline={
                            <span>
                                Dienstleistungen &ndash;
                                <br />
                                rund ums Haus
                            </span>
                        }
                    >
                        {/* eslint-enable react/jsx-wrap-multilines */}
                        <P>
                            Ob Gebäude- oder Glasreinigung, Desinfektion oder Schädlingsbekämpfung -
                            wir bieten Ihnen Erfahrung und Kompetenz rund um Wartung, Pflege und
                            Hygiene. Sauber und diskret sowie im Umfang exakt auf Ihre Wünsche und
                            Anforderungen abgestimmt.
                        </P>
                    </Intro>
                    <Img
                        fluid={
                            getImageFromNode(contentImages, 'services/contentimage-intro.jpg').node
                                .childImageSharp.fluid
                        }
                    />
                </Container>
            </Section>
            <Section bg="gray" id="gebaeudereinigung-und-wartung">
                <Container size="l" grid cols="2">
                    <Img
                        fluid={
                            getImageFromNode(
                                contentImages,
                                'services/contentimage-gebaeudereinigung.jpg'
                            ).node.childImageSharp.fluid
                        }
                    />
                    {/* eslint-disable react/jsx-wrap-multilines */}
                    <Intro headline="Gebäudereinigung & Wartung">
                        {/* eslint-enable react/jsx-wrap-multilines */}
                        <P>
                            Wir wollen, dass Sie sich im Büro, in Ihrem Unternehmen oder bei Ihnen
                            Zuhause rundum wohl fühlen. Deshalb tragen wir mit Reinigung und Pflege
                            dazu bei, Ihre Materialien dauerhaft zu erhalten, im Innen- wie im
                            Außenbereich. Ob Stein, Holz oder Leder, wir haben für jedes ihrer
                            Materialien die richtige Pflegemethode.
                        </P>
                        <List
                            title="Reinigung:"
                            gap="xl"
                            items={[
                                'Wir säubern Ihre Böden aus Stein, Holz, Linoleum, PVC- und CV-Belägen. Inklusive anschließender Einpflegung und Beschichtung',
                                'Wir pflegen Ihre Polstermöbel - mit kurzer Trockenzeit',
                                'Wir reinigen ihre Teppichböden so, dass Sie diese anschließend sofort wieder betreten können',
                                'Wir frischen Ihre Ledermöbel auf',
                                'Wir putzen Ihre Fenster, Glaskonstruktionen, Wintergärten und Sonnenkollektoren',
                            ]}
                            showBullets
                        />
                        <List
                            title="Wartung:"
                            gap="xl"
                            items={[
                                'Dachrinnenreinigung',
                                'Rasen- und Heckenpflege',
                                'Hochdruckreinigungsarbeiten (Terrassen- und Treppenbereiche)',
                            ]}
                            showBullets
                        />
                        <List
                            title="Kleintransporte:"
                            items={['Wir übernehmen Transporte bis 750 Kilo']}
                            showBullets
                        />
                    </Intro>
                </Container>
            </Section>
            <Section id="schaedlingsbekaempfung-und-desinfektion">
                <Container size="l" grid cols="2" reverseOnMobile>
                    <Intro headline="Schädlingsbekämpfung & Desinfektion">
                        <P>
                            Besonders Betriebe in der Lebensmittelwirtschaft, Gastronomie und der
                            Gemeinschaftsverpflegung sind in hohem Maße gefährdet, durch
                            Warensendungen Schädlinge einzuschleppen. Doch auch Privathaushalte sind
                            vor Schädlingen nicht geschützt.
                        </P>
                        <List
                            title="Wir wissen, was bei akutem Befall zu tun ist:"
                            gap="xl"
                            items={[
                                'Angefangen bei Prävention und Bekämpfung von Material- und Vorratsschädlingen (Schaben, Ameisen, Wespen o.Ä.)',
                                'über Taubenabwehr',
                                'und Mäuse- und Rattenbekämpfung',
                                'bis hin zu Holz- und Bautenschutz',
                            ]}
                            showBullets
                        />
                        <P>
                            Es genügt nicht, die Schädlinge zu bekämpfen. Vielmehr muss man ihnen -
                            durch Desinfektion und durchgängige Hygiene - jeglichen Nährboden
                            entziehen, um einem erneuten Befall konsequent vorzubeugen. Deshalb
                            setzen wir ganz auf die integrierte Schädlingsbekämpfung.
                        </P>
                    </Intro>
                    <Img
                        fluid={
                            getImageFromNode(
                                contentImages,
                                'services/contentimage-schaedlingsbekaempfung.jpg'
                            ).node.childImageSharp.fluid
                        }
                    />
                </Container>
            </Section>
            <Section bg="gray" id="schulung-und-beratung">
                <Container size="l" grid cols="2">
                    <Img
                        fluid={
                            getImageFromNode(contentImages, 'services/contentimage-schulung.jpg')
                                .node.childImageSharp.fluid
                        }
                    />
                    <Intro headline="Schulung & Beratung">
                        <P>
                            Im Bereich der Hygiene verfügen wir über fundiertes Fachwissen. Dieses
                            Wissen geben wir gerne weiter und bieten Ihnen eine umfassende Beratung
                            sowie eine fachgerechte Schulung Ihrer Mitarbeiter im Lager, in der
                            Küche und in allen anderen hygienerelevanten Abteilungen.
                        </P>
                        <List
                            title="Wir vermitteln Ihnen die wichtigsten Grundzüge der gängigsten Qualitätsstandards:"
                            gap="xl"
                            items={[
                                'EG-Richtlinie / EWG 93/94 (HACCP)',
                                'EU-Lebensmittelhygieneverordnung',
                                'QS-Elemente aus DIN ISO 9000 ff',
                                'Grundlagen der Hygienesicherung',
                                'Qualitätsprüfungen mikrobiell und visuell',
                                'Personalschulung gemäß DIN 10514',
                                'GMP (Good Manufactoring Practice) - gute Herstellungspraxis',
                            ]}
                            showBullets
                        />
                        <P>
                            {/* eslint-disable  */}
                            Unser Fachwissen haben wir am renommierten{' '}
                            <strong>
                                Institut für Hygiene und Biotechnologie am Forschungsinstitut
                                Hohenstein
                            </strong>{' '}
                            und dem <strong>Landesgesundheitsamt Baden-Württemberg</strong>{' '}
                            erworben.
                            {/* eslint-enable  */}
                        </P>
                    </Intro>
                </Container>
            </Section>
            <Section id="bodenverlegung-und-reparaturen">
                <Container size="l" grid cols="2" reverseOnMobile>
                    <Intro headline="Bodenverlegung & Reparaturen">
                        <P>
                            Schütz Dienstleistungen bietet Ihnen abseits des Gebäudereinigungs- und
                            Hygienesektors auch fachgerechte Verlegung, Reparatur und Pflege von
                            Bodenbelägen.
                        </P>
                        <List
                            title="Wir kennen uns aus mit:"
                            items={['Teppichboden', 'Fertigparkett', 'Korkboden', 'Laminat']}
                            showBullets
                        />
                    </Intro>
                    <Img
                        fluid={
                            getImageFromNode(
                                contentImages,
                                'services/contentimage-bodenverlegung.jpg'
                            ).node.childImageSharp.fluid
                        }
                    />
                </Container>
            </Section>
            <ContactTeaser />
            {/* <Section>
                <Headline level="h2" textAlign="center">
                    Eine Auswahl unserer letzten Projekte
                </Headline>
            </Section> */}
        </Layout>
    );
};

ServicesPage.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.object.isRequired,
};

/**
 * Query für benötigte Assets der Seite
 * @type {GraphQL}
 */
export const query = graphql`
    {
        stageImage: file(relativePath: { glob: "services/stage-image.jpg" }) {
            ...StageFluid
        }
        contentImages: allFile(
            filter: { relativePath: { glob: "services/contentimage-*.jpg" } }
            sort: { fields: [relativePath], order: ASC }
        ) {
            edges {
                node {
                    relativePath
                    childImageSharp {
                        fluid(maxWidth: 585, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`;

export default ServicesPage;
